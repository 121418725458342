<template>
  <div>
    <FormError :errors="errorSave" />
    <div class="flex justify-between">
      <InputForm
        :label="$t('global_firstname')"
        v-model="account.firstName"
        classAdd="w-full mr-1 flex flex-col justify-start items-start"
        :error="errors.firstName"
      />
      <InputForm
        :label="$t('global_lastname')"
        v-model="account.lastName"
        classAdd="w-full mr-1 flex flex-col justify-start items-start"
        :error="errors.lastName"
      />
    </div>

    <InputForm
      :label="$t('account_clinic_name')"
      v-model="account.practiceName"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.practiceName"
    />

    <InputForm
      :label="$t('account_business_website')"
      v-model="account.website"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.website"
    />

    <InputForm
      :label="$t('account_business_tax_id') + '*'"
      v-model="account.taxID"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.taxID"
    />

    <InputForm
      :label="$t('global_street_address')"
      v-model="account.streetAddress"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.streetAddress"
    />

    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="country">{{
          $t("global_country")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.country"
          @change="inputSelectCountry()"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.country" />
      </div>
      <div class="w-full ml-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="state">{{
          $t("global_state")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.state"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in statesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.state" />
      </div>
    </div>

    <div class="mt-5 flex justify-between">
      <InputForm
        :label="$t('global_city')"
        v-model="account.city"
        classAdd="w-full mr-1 flex flex-col justify-start items-start"
        :error="errors.city"
      />
      <InputForm
        :label="$t('global_postal_code')"
        v-model="account.postalCode"
        classAdd="w-full ml-1 flex flex-col justify-start items-start"
        :error="errors.postalCode"
      />
    </div>
  </div>
</template>

<script>
import InputForm from "../../forms/InputForm.vue";
import InputRadio from "../../forms/InputRadio.vue";
import FormError from "../../warning/FormError.vue";

import { getCountries } from "../../../services/countriesServices";
import { getStates } from "../../../services/stateServices";
import { saveVeterinarian } from "../../../services/veterinarianService";

import { mapActions } from "vuex";

export default {
  name: "signInAfterLogin",
  props: {
    email_address: String,
    phone_number: String,
    provider: String,
    practiceType: Number,
    checkboxNews: Boolean,
  },

  components: {
    InputForm,
    InputRadio,
    FormError,
  },
  data() {
    return {
      account: {
        firstName: "",
        lastName: "",
        streetAddress: "",
        country: "",
        state: "",
        city: "",
        postalCode: "",
        website: "",
        practiceName: "",
        taxID: "",
      },
      errors: {},
      errorSave: "",
      countriesList: [],
      statesList: [],
      coordinates: { lng: 3.05997, lat: 36.7762 },
    };
  },
  created() {
    this.$getLocation({}).then((coordinates) => {
      this.coordinates = coordinates;
    });
  },
  async mounted() {
    const a = await getCountries();
    this.countriesList = a.data;
  },
  async created() {
    const a = await getCountries();
    this.countriesList = a.data;
    console.log(this.countriesList);
    if (this.account.country) {
      const c = await getStates(this.account.country);
      this.statesList = c.data;
      console.log(this.statesList);
    }
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      signInAfterLogin: "auth/signInAfterLogin",
    }),
    async inputSelectCountry() {
      const a = await getStates(this.account.country);
      this.statesList = a.data;
    },

    validate() {
      const errors = {};

      if (this.account.firstName.trim() === "")
        errors.firstName = "FirstName  is required";
      if (this.account.lastName.trim() === "")
        errors.lastName = "LastName  is required";
      if (this.account.streetAddress.trim() === "")
        errors.streetAddress = "Address  is required";
      if (this.account.country === "")
        errors.country = this.$t("global_error_country");
      if (this.account.state === "") errors.state = "State  is required";
      if (this.account.city.trim() === "") errors.city = "City  is required";
      if (this.account.postalCode.trim() === "")
        errors.postalCode = this.$t("global_error_postal_code");
      if (this.account.practiceName.trim() === "")
        errors.practiceName = this.$t("account_error_clinic_name");
      if (this.account.taxID.trim() === "")
        errors.errorTaxID = this.$t("account_error_business_tax_id");

      return Object.keys(errors).length === 0 ? null : errors;
    },

    async submitForm(image) {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      try {
        let { data } = await saveVeterinarian(
          this.provider,
          image,
          this.account,
          this.email_address,
          this.phone_number,
          this.practiceType,
          this.checkboxNews ? 1 : 0,
          this.coordinates.lng,
          this.coordinates.lat
        );
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        //this.signInAfterLogin(data.login_data);
        this.$router.push("AccountConfirmation");
      } catch (error) {
        this.errorSave = this.$t("account_error_save_button");
      }
    },
  },
};
</script>

<style></style>
