<template>
  <div class="mt-6 mb-10 md:my-0 flex flex-col md:flex-row items-start">
    <SectionLeft />
    <div class="h-full md:h-screen w-full md:w-6/12 px-4 md:pr-16 lg:pr-32">
      <CompleteprofileSection
        :email_address="this.$route.params.email_address"
        :phone_number="this.$route.params.phone_number"
        :provider="this.$route.params.provider"
        :practiceType="this.$route.params.practiceType"
      />
    </div>
    <DataserviceLogo />
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import CompleteprofileSection from "../components/completeyourprofile/veterinarian/CompleteprofileSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue"

export default {
  components: {
    
    SectionLeft,
    CompleteprofileSection,
    DataserviceLogo
  },
};
</script>
